import completedGames from "../data/completedGames.json";

const CompletedGames = () => {
  return (
    <div style={{ paddingTop: 50 }}>
      {completedGames.matches.map((game, index) => {
        const resultTextColor = game.result === "W" ? "green" : "red";
        const startDate = new Date(game.startDateTime);
        return (
          <div
            key={index}
            style={{
              borderBottomStyle: "solid",
              borderWidth: 1,
              borderBottomColor: "black",
            }}
          >
            <p style={{ fontSize: 12 }}>
              Date:
              {startDate.toLocaleDateString([], {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              {" - "}
              {startDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            <p style={{ fontSize: 12 }}>Opponent: {game.opponent}</p>
            <p style={{ fontSize: 12 }}>Location: {game.location}</p>
            <p style={{ fontSize: 12 }}>Court Number: {game.courtNumber}</p>
            <p style={{ fontSize: 12, color: resultTextColor }}>
              Result: {game.result}
            </p>
            <p style={{ fontSize: 12 }}>Score: {game.score}</p>
          </div>
        );
      })}
    </div>
  );
};

export default CompletedGames;
