import React from "react";
import eventsData from "../data/events.json";

const BasketballEvents: React.FC = () => {
  const currentDate = new Date();
  return (
    <div style={{ paddingTop: 25 }}>
      {eventsData.map((event) => {
        const startDate = new Date(event.eventStartDateTime);
        const endDate = new Date(event.eventEndDateTime);

        // If the event is in the past, don't show it
        if (endDate < currentDate) {
          return null;
        }
        return (
          <div
            style={{
              borderBottomStyle: "solid",
              borderWidth: 1,
              borderBottomColor: "black",
              width: "100%",
            }}
            key={event.id}
          >
            <h3 style={{ color: "darkblue" }}>
              {event.eventType} - {event.eventLocation}
            </h3>
            {event.opponent && (
              <p style={{ color: "green" }}>Playing: {event.opponent}</p>
            )}

            <p>
              Date:{" "}
              {startDate.toLocaleDateString([], {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
            <p>
              Time:{" "}
              {startDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {endDate.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
            <p style={{ fontWeight: "bold", color: "#CC5500" }}>
              {event.notes}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",

                width: "25%",
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BasketballEvents;
