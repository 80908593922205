import React, { useState } from "react";
import "./App.css";
import BasketballEvents from "./features/basketballEvents";
import CompletedGames from "./features/completedGames";
import TeamRoster from "./features/teamRoster";

function App() {
  const [buttonSelected, setButtonSelected] = useState("Practice/Games");

  const onClick = (selection: string) => {
    setButtonSelected(selection);
  };

  const completedButtonColor =
    buttonSelected === "CompletedGames" ? "#33b249" : "black";
  const practiceButtonColor =
    buttonSelected === "Practice/Games" ? "#33b249" : "black";
  const rosterButtonColor = buttonSelected === "Roster" ? "#33b249" : "black";

  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <header style={{ paddingBottom: 50 }}>
        <div
          style={{
            fontWeight: "bold",
            paddingTop: 25,
            fontSize: 34,
            color: "#00146f",
            position: "fixed",
            top: 0,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div style={{ flex: 1, flexDirection: "row" }}>
            <button
              style={{
                flex: 1,
                marginRight: 10,
                color: completedButtonColor,
                justifyContent: "center",
              }}
              onClick={() => onClick("CompletedGames")}
            >
              Completed Games
            </button>
            <button
              style={{
                flex: 1,
                marginRight: 10,
                color: practiceButtonColor,
                justifyContent: "center",
              }}
              onClick={() => onClick("Practice/Games")}
            >
              Upcoming Practices/Games
            </button>
            <button
              style={{ flex: 1, color: rosterButtonColor }}
              onClick={() => onClick("Roster")}
            >
              Roster
            </button>
          </div>
          Coach Radish Practice and Game Schedule
        </div>
      </header>

      <header>
        <div
          style={{
            fontWeight: "bold",
            paddingTop: 50,
            fontSize: 20,
            color: "#000000",
            backgroundColor: "white",
          }}
        >
          {buttonSelected === "CompletedGames" && <CompletedGames />}
          {buttonSelected === "Practice/Games" && <BasketballEvents />}
          {buttonSelected === "Roster" && <TeamRoster />}
        </div>
      </header>
    </div>
  );
}

export default App;
