import teamRoster from "../data/teamRoster.json";

const TeamRoster = () => {
  return (
    <div style={{ paddingTop: 25 }}>
      <h1>Team Roster</h1>
      {teamRoster.map((player, index) => (
        <div key={index}>
          <h3>
            {player.firstName} #{player.number}
          </h3>
        </div>
      ))}
    </div>
  );
};

export default TeamRoster;
